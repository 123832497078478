<!--  -->
<template>
  <div>
    <div class="mainCont"></div>
    <div class="def-container tableCont">
      <div class="bannerTxt">
        <span class="chTxt">政策指标匹配计算</span><br />
        <span class="enTxt">MATCHING CALCULATION OF POLICY INDICATORS</span>
      </div>
      <div class="box-card def-container">
        <el-row style="margin-bottom: 8px">
          <span style="color: #0069e8; font-size: 16px"
            >【{{ thisProject.areaid }}】</span
          >
          <span style="color: #333; font-weight: 700; font-size: 16px">{{
            thisProject.name
          }}</span>
          <!-- <span class="match">匹配度：{{ thisProject.ppd }}%</span> -->
        </el-row>

        <el-row class="box-line">
          <el-col :span="2" class="box-line-title">发布日期:</el-col>
          <el-col :span="20">{{
            moment(thisProject.starttime).format("YYYY-MM-DD")
          }}</el-col>
        </el-row>
        <el-row class="box-line">
          <el-col :span="2" class="box-line-title">发文单位:</el-col>
          <el-col :span="20">{{ thisProject.deptname }}</el-col>
        </el-row>
        <el-row class="box-line">
          <el-col :span="2" class="box-line-title">政策名称:</el-col>
          <el-col :span="20">{{ thisProject.policyName }}</el-col>
        </el-row>
        <el-row class="box-line">
          <el-col :span="2" class="box-line-title">申报时间:</el-col>
          <el-col :span="20"
            >{{ moment(thisProject.starttime).format("YYYY-MM-DD") }}至{{
              moment(thisProject.endtime).format("YYYY-MM-DD")
            }}
          </el-col>
        </el-row>
        <el-row class="box-line">
          <el-col :span="2" class="box-line-title">扶持资金:</el-col>
          <el-col :span="20">{{ thisProject.zzxe }}（万元）</el-col>
        </el-row>
        <div
          class="zct-policy-item-comp__rect is-grey"
          v-if="!thisProject.ksbflag"
        >
          非申报期
        </div>
        <div v-else class="zct-policy-item-comp__rect is-blue">申报期</div>
        <div class="wssb">
          <!-- <el-button
              type="primary"
              :disabled="!thisProject.ksbflag"
              @click="goDeclare(thisProject)"
              >我要申报</el-button
            > -->
        </div>
      </div>
    </div>
    <div class="def-container radioConBg">
      <div class="radioCon">
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio-button label="政策指标"></el-radio-button>
          <!--          <el-radio-button label="项目详情信息"></el-radio-button>-->
          <el-radio-button label="政策详情"></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div
      class="def-container"
      style="background-color: #fff; margin-bottom: 160px; padding: 15px 0"
      v-if="indexFlag == '1'"
    >
      <!-- <el-row style="padding: 20px 0">
        <el-col :span="21" align="center" class="tableInfo"
          >该政策供{{ data.ppTotle ? data.ppTotle : 0 }}项指标目标，企业符合{{
            data.ppNum ? data.ppNum : 0
          }}项，匹配度{{ data.ppd ? data.ppd : 0 }}%
        </el-col>
        <el-col :span="3" align="center" class="tableInfo"></el-col>
      </el-row> -->
      <el-table :data="applyTableData" style="width: 100%">
        <el-table-column prop="name" label="政策指标" width="250">
        </el-table-column>
        <el-table-column prop="value" label="政策指标详情" width="400">
        </el-table-column>
        <el-table-column prop="value1" label="企业指标详情" width="400">
        </el-table-column>
        <el-table-column prop="pp" label="企业情况">
          <template slot-scope="scope">
            <span v-if="scope.row.pp == 1">
              <i class="el-icon-success" style="color: #55ca15"></i> 匹配
            </span>
            <span v-if="scope.row.pp != 1">
              <i class="el-icon-error" style="color: #d11111"></i> 不匹配
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="100">
            <template>
              <el-button type="text" size="small">编辑</el-button>
            </template>
          </el-table-column> -->
      </el-table>
    </div>
    <div
      v-if="indexFlag == '2'"
      class="def-container"
      style="background-color: #fff; margin-bottom: 160px; padding: 15px 50px"
    >
      <!-- 代渲染 -->
      <div class="detail-content">
        <div class="box-card" v-html="itemData.content"></div>
      </div>
    </div>
    <div
      style="
        background-color: #fff;
        margin-bottom: 160px;
        padding: 15px 50px;
        overflow: hidden;
      "
      class="def-container"
      v-if="indexFlag == '3'"
    >
      <div class="def-container">
        <div class="box-card">
          <el-row style="margin-bottom: 8px">
            <span style="color: #0069e8; font-size: 16px"
              >【{{ thisProject.areaid }}】</span
            >
            <span style="color: #333; font-weight: 700; font-size: 16px">{{
              thisProject.name
            }}</span>
          </el-row>

          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">发布日期:</el-col>
            <el-col :span="10">{{
              moment(thisProject.starttime).format("YYYY-MM-DD")
            }}</el-col>
            <el-col :span="2" class="box-line-title">发文单位:</el-col>
            <el-col :span="10">{{ thisProject.deptname }}</el-col>
          </el-row>
          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">政策名称:</el-col>
            <el-col :span="10">{{ thisProject.policyName }}</el-col>
            <el-col :span="2" class="box-line-title">申报时间:</el-col>
            <el-col :span="10"
              >{{ moment(thisProject.starttime).format("YYYY-MM-DD") }}至{{
                moment(thisProject.endtime).format("YYYY-MM-DD")
              }}
            </el-col>
          </el-row>
          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">扶持资金:</el-col>
            <el-col :span="10">{{ thisProject.zzxe }}（万元）</el-col>
            <!-- <el-col :span="2" class="box-line-title">注册要求:</el-col> -->
            <el-col :span="10">{{ thisProject.requireRegist }}</el-col>
          </el-row>
          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">付款地点:</el-col>
            <el-col :span="10">{{ thisProject.paymentPlace }}</el-col>
            <el-col :span="2" class="box-line-title">所属行业:</el-col>
            <el-col :span="10">{{ thisProject.sysCategoryName }}</el-col>
          </el-row>
          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">企业注册类型:</el-col>
            <el-col :span="10">{{
              thisProject.sysEnterpriseRegistertypeName
            }}</el-col>
            <el-col :span="2" class="box-line-title">周期:</el-col>
            <el-col :span="10">{{ thisProject.growthCycleName }}</el-col>
          </el-row>
          <el-row class="box-line">
            <el-col :span="2" class="box-line-title">企业属性名称:</el-col>
            <el-col :span="10">{{
              thisProject.enterpriseAttributesName
            }}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      id: "",
      policyVisible: false,
      applyTableData: [],
      itemData: {},
      indexFlag: "1",
      radio: "政策指标",
      tableData: [],
      thisProject: "",
      moment,
    };
  },

  components: {},

  created() {
    const that = this;
    const id = that.$route.params.listData.policyId; // 接收的参数ID
    that.id = id;
    that.getNoticeInfo(id);
    that.thisProject = that.$route.params.listData;
    console.log(that.thisProject);
    this.projectShow();
    this.getDataList();
  },

  mounted() {
    /*const that = this;
            const id = that.$route.params.listData.policyId; // 接收的参数ID
            that.id = id;
            that.getNoticeInfo(id);
            this.projectShow();
            this.getDataList();*/
  },

  methods: {
    goDeclare(item) {
      // this.$router.push({
      //   name: 'policyDeclaration',
      //   query: {
      //     policyId: sxid,
      //   },
      // })
      this.$store.commit("updateCurrentGuideId", item.sxid);
      this.$router.push({
        path: "/guideSuqiu",
        query: {
          name: item.name,
          itemType: "2",
          isAdded: item.isAdded,
        },
      });
    },
    /**
     * 公告详细信息
     * @param {string} id 传项目id
     */
    getNoticeInfo(id) {
      let that = this;
      if (id) {
        this.$httpApi
          .get(`/dev-api/document/${id}`, {})
          .then((res) => {
            if (res.code == 200) {
              that.itemData = res.data;
              let data = res.data;
              this.deptObject = {
                deptName: data.deptname,
                deptId: data.deptid,
              };
            }
          })
          .catch((err) => {});
      }
    },
    openPolicy() {
      this.policyVisible = true;
    },
    //根据政策id查询项目
    projectShow() {
      let zcjsqData = JSON.parse(sessionStorage.getItem("zcjsqData"));
      let url = "/dev-api/business/sq-apply/selectProjectList";
      if (zcjsqData) {
        let params = {
          policyId: zcjsqData.id,
          pageSize: 100,
          pageNum: 1,
          serachProject: "",
        };
        let that = this;
        that.$httpApi
          .post(url, params)
          .then((r) => {
            this.tableData = r.list;
          })
          .catch((e) => {});
      }
    },
    tableRowClick(row, column, event) {
      let href = "/#/guideSuqiu?name=" + row.name + "&itemType=2";
      window.open(href);
    },
    radioChange(val) {
      if (val == "项目详情信息") {
        this.indexFlag = "3";
      }
      if (val == "政策指标") {
        this.indexFlag = "1";
      }
      if (val == "政策详情") {
        this.indexFlag = "2";
      }
    },
    getDataList() {
      this.data = this.$route.params.listData;
      this.json = this.data.json;
      if (this.json) {
        this.form = this.json;
      } else {
        this.form = {};
      }
      this.applyTableData = [
        {
          name: "行业类别",
          value: this.data.sysCategoryName ? this.data.sysCategoryName : "",
          value1: this.data.sysCategoryEnterpriseName
            ? this.data.sysCategoryEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.sysCategoryName
          //     ? this.json.sysCategoryName
          //     : "",
          pp: this.data.sysCategoryPp ? this.data.sysCategoryPp : "",
        },
        {
          name: "企业规模",
          value: this.data.enterpriseSizeName
            ? this.data.enterpriseSizeName
            : "",
          value1: this.data.enterpriseSizeEnterpriseName
            ? this.data.enterpriseSizeEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.enterpriseSizeName
          //     ? this.json.enterpriseSizeName
          //     : "",
          pp: this.data.enterpriseSizePp ? this.data.enterpriseSizePp : "",
        },
        {
          name: "企业属性",
          value: this.data.enterpriseAttributesName
            ? this.data.enterpriseAttributesName
            : "",
          value1: this.data.enterpriseAttributesEnterpriseName
            ? this.data.enterpriseAttributesEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.enterpriseAttributesName
          //     ? this.json.enterpriseAttributesName
          //     : "",
          pp: this.data.enterpriseAttributesPp
            ? this.data.enterpriseAttributesPp
            : "",
        },
        {
          name: "成长周期",
          value: this.data.growthCycleName ? this.data.growthCycleName : "",
          value1: this.data.growthCycleEnterpriseName
            ? this.data.growthCycleEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.growthCycleName
          //     ? this.json.growthCycleName
          //     : "",
          pp: this.data.growthCyclePp ? this.data.growthCyclePp : "",
        },
        {
          name: "产业类别",
          value: this.data.sysEnterTypeName ? this.data.sysEnterTypeName : "",
          value1: this.data.sysEnterTypeEnterpriseName
            ? this.data.sysEnterTypeEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.sysEnterTypeName
          //     ? this.json.sysEnterTypeName
          //     : "",
          pp: this.data.sysEnterTypePp ? this.data.sysEnterTypePp : "",
        },
        // {
        //   name: "注册要求",
        //   value: this.data.requireRegist ? this.data.requireRegist : "",
        //   value1:
        //     this.json != null && this.json.requireRegist
        //       ? this.json.requireRegist
        //       : "",
        //   pp: this.data.requireRegistPp ? this.data.requireRegistPp : "",
        // },
        {
          name: "纳税地",
          value: this.data.paymentPlace ? this.data.paymentPlace : "",
          value1:
            this.json != null && this.json.paymentPlace
              ? this.json.paymentPlace
              : "",
          pp: this.data.paymentPlacePp ? this.data.paymentPlacePp : "",
        },
        {
          name: "企业注册类型",
          value: this.data.sysEnterpriseRegistertypeName
            ? this.data.sysEnterpriseRegistertypeName
            : "",
          value1: this.data.sysEnterpriseRegistertypeEnterpriseName
            ? this.data.sysEnterpriseRegistertypeEnterpriseName
            : "",
          // value1:
          //   this.json != null && this.json.sysEnterpriseRegistertypeName
          //     ? this.json.sysEnterpriseRegistertypeName
          //     : "",
          pp: this.data.sysEnterpriseRegistertypePp
            ? this.data.sysEnterpriseRegistertypePp
            : "",
        },
        {
          name: "主营业务收入",
          value: this.data.mainbusinessIncome
            ? this.data.mainbusinessIncome
            : "0",
          value1:
            this.json != null && this.json.mainbusinessIncome
              ? this.json.mainbusinessIncome
              : "0",
          pp: this.data.mainbusinessIncomePp
            ? this.data.mainbusinessIncomePp
            : "",
        },
        {
          name: "上一年度企业所得税（单位：万元）",
          value: this.data.lastIncomeTax ? this.data.lastIncomeTax : "0",
          value1:
            this.json != null && this.json.lastIncomeTax
              ? this.json.lastIncomeTax
              : "0",
          pp: this.data.lastIncomeTaxPp ? this.data.lastIncomeTaxPp : "",
        },
      ];
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-radio-button.is-active .el-radio-button__inner {
  border: none !important;
  // background-color: #22b7fd;
  background-color: #cbe9ff;
  color: #1f59d8;
}

::v-deep .el-radio-button {
  border-color: none;
}

::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}

::v-deep .el-radio-button__inner {
  border: none;
  color: #666666;
  padding: 17px 64px;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
  // border-left: 1px solid #22b7fd;
  border-left: transparent;
  border-radius: 0;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  box-shadow: none !important;
}

.detail-content {
  line-height: 28px;
  font-size: 14px;
  margin: 20px 0;
  overflow: hidden;

  p {
    text-indent: 2rem;
  }
}

.line_link {
  font-size: 16px;
  color: #3c67a8;
  text-decoration: underline;
}

.desc {
  margin-bottom: 15px;
  color: #fff;
  letter-spacing: 2px;
}

.line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
  font-weight: 600;

  .line_label {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }

  .line_value {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
}

.line_link {
  font-size: 16px;
  color: #3c67a8;
  text-decoration: underline;
}

.tableInfo {
  background-color: #fffaf3;
  color: orange;
  height: 48px;
  line-height: 48px;
}

.tableCont {
  background-color: #fff;
  margin: 16px auto;
  height: 220px;
  position: relative;
  .bannerTxt {
    position: absolute;
    top: -163px;
    left: 25px;
    color: #fff !important;
    background: transparent;
    .chTxt {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
    }
    .enTxt {
      font-size: 22px;
      font-weight: 500;
      word-spacing: 5px;
      letter-spacing: 2px;
    }
  }
}

.rightCont {
  width: 200px;
  height: 100px;
  position: absolute;
  top: 0;
  right: -220px;

  div {
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .rightCont_company {
    background-color: #22b7fd;
    color: #fff;
  }

  .rightCont_info {
    background-color: #fff;
    color: #333;
  }
}

::v-deep .el-table th,
.el-table tr {
  background-color: #22b7fd;
}

::v-deep .el-table thead {
  color: #fff;
}
.radioConBg {
  height: 50px;
  border: 1px solid #eeeeee;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
}
.radioCon {
  width: 100%;
  margin-left: -16px;
}

.applyTable {
  color: #000;
  font-size: 16px;

  .contentCon {
    height: 100px;

    div {
      margin: 10px auto;
    }
  }

  .elBtn {
    margin-left: 40px;
  }

  .fuchi {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subTitle {
    color: #00a0ff;
    font-weight: 600;
  }

  .restDay {
    width: 90px;
    height: 90px;
    border: 1px solid #ffe8c6;
  }
}
</style>
<style lang='scss' scoped>
.wssb {
  width: 117px;
  height: 34px;
  position: absolute;
  top: 100px;
  right: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  z-index: 2;
}
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/jztuisong.png") no-repeat 100% / cover;
  display: flex;
  align-items: center;

  .def-container {
    .main_title {
      font-size: 28px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .desc {
      margin-bottom: 15px;
      color: #fff;
      letter-spacing: 2px;
    }

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .line_label {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
      }

      .line_value {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
      }
    }

    .line_link {
      font-size: 16px;
      color: #3c67a8;
      text-decoration: underline;
    }
  }
}

.home-title {
  height: 50px;
  line-height: 2.5;
  width: 100%;
  background-color: #d4dff2;

  .home-title_1 {
    height: 100%;
    width: 60%;
    margin-left: 15%;

    .home-title_2 {
      height: 100%;
      width: 221px;
      float: left;
      text-align: center;
      font-size: 17px;
      padding: 8px;
      cursor: pointer;
    }

    // .home-title_2:hover {
    //   height: 100%;
    //   width: 221px;
    //   float: left;
    //   text-align: center;
    //   font-size: 17px;
    //   padding: 8px;
    //   cursor: pointer;
    //   color: #ffffff;
    //   background-color: #0069e8;
    // }
    .home-title_2s {
      height: 100%;
      width: 221px;
      float: left;
      text-align: center;
      font-size: 17px;
      padding: 8px;
      cursor: pointer;
      color: #ffffff;
      background-color: #0069e8;
    }

    .home-title_3 {
      color: #000000;
    }

    .home-title_3:hover {
      color: #ffffff;
    }
  }
}

.report-container {
  background-color: #f0f2f5;

  .report-container__filter {
    width: 78.125rem;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .report-container__filter__item {
      width: 126px;
      height: 53px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      text-align: center;
    }

    .report-container__filter__item.is-active {
      font-size: 18px;
      color: #0069e8;
    }
  }
}

.qw-list-empty {
  width: 100%;
  min-height: 200px;
  height: 30vh;
  position: relative;
  background: #fff;

  .qw-list-empty__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #989899;

    span {
      font-size: 18px;
      line-height: 24px;
    }

    i {
      font-size: 36px;
      line-height: 80px;
    }
  }
}

.box-card {
  margin: 20px auto;
  min-height: 200px;
  position: relative;
  background: #fff;
  cursor: pointer;
  // box-shadow: 1px 1px 1px 1px #ccc;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px 8px;

  .box-line {
    margin-bottom: 6px;
    display: flex;
    font-size: 14px;
    color: #666;
    line-height: 26px;

    .box-line-title {
      font-weight: 600;
    }
  }
}

.match {
  cursor: pointer;
  display: block;
  font-size: 12px;
  border: 1px solid #cce1fa;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
  background-color: #fef0f0;
  border-color: #fde2e2;
  color: #f56c6c;
  float: right;
  margin-right: 10%;
}

// .zct-policy-item-comp__rect:before {
//   content: "";
//   width: 0;
//   height: 0;
//   border-bottom: 34px solid #fff;
//   border-right: 14px solid transparent;
//   position: absolute;
//   bottom: 0;
//   left: 0;
// }

.zct-policy-item-comp__rect.is-grey {
  background-color: #9b9b9b;
}

.zct-policy-item-comp__rect.is-blue {
  background-color: #89dc4a;
}

.zct-policy-item-comp__rect {
  width: 117px;
  height: 34px;
  position: absolute;
  top: 0;
  right: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a71d1d;
  font-size: 18px;
  color: #fff;
  z-index: 2;
}
@media screen and (max-width: 1366px) {
  .zct-policy-item-comp__rect {
    right: 0;
  }
}
</style>
